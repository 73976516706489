
import styled, { css } from 'styled-components'
import { FaLock as LockIcon, FaUnlockAlt as UnlockedIcon } from 'react-icons/fa'

const LockIcons = css`
    margin-left: auto;
    flex-shrink: 0;

    & * {
        pointer-events: none;
    }
`

export const Lock = styled(LockIcon).attrs({
    'aria-label': 'Locked'
})`
    ${LockIcons}
`

export const Unlocked = styled(UnlockedIcon).attrs({
    'aria-label': 'Unlocked'
})`
    ${LockIcons}
`