
import React from 'react'
import styled from 'styled-components'
import { Accordion } from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import { Button } from 'components/form/button'
import { GeneratorSection } from 'components/generator/section'

const StyledWrapper = styled.div`
    max-width: 80%;
    margin: 0 auto;
    padding-top: 25px;
`

export default class Generator extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let { pageContext: { generatorObject } } = this.props

        return <StyledWrapper>
            <Button type='submit'>Generate</Button>
            <Accordion>
                <GeneratorSection {...generatorObject} />
            </Accordion>
        </StyledWrapper>
    }

}