
import React from 'react'
import styled from 'styled-components'
import {
    Accordion as VanillaAccordion,
    AccordionItem as VanillaItem,
    AccordionItemTitle as VanillaItemTitle,
    AccordionItemBody as VanillaItemBody,
} from 'react-accessible-accordion'

export const Accordion = styled(({ injectStyle, ...props }) => <VanillaAccordion {...props} />).attrs({
    'role': 'tablist'
})`
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin: 10px 0;

    ${props => props.injectStyle}
`

export const AccordionItem = styled(VanillaItem)`
`

export const AccordionItemTitle = styled(VanillaItemTitle)`
    display: flex;
    align-items: center;
    position: relative;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    background-color: ${props => props.bg || '#f4f4f4'};

    padding: 15px;

    & > * {
        margin: 0 10px;
        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &:hover {
        background-color: ${props => props.bghover || '#dadada'};
    }
`

export const AccordionItemBody = styled(VanillaItemBody)`
    padding: 10px;
    padding-right: 5px;
`

export const AccordionItemTitleIcons = styled.div`
    margin: 10px;
    margin-left: auto;
    flex-shrink: 0;

    & > * {
        margin: 0 5px;

        &:last-child {
            margin-left: 0;
        }
    }

    a {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
    }

    button {
        cursor: pointer;
        margin-top: 0;
        margin-bottom: 0;
    }
`

export const AccordionArrow = styled.div`
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    padding: 0;

    &:before, &:after {
        display: block;
        position: absolute;
        top: 50%;
        width: 10px;
        height: 2px;
        background-color: currentColor;
        content: '';
        transition: transform 0.25s ease, -webkit-transform 0.25s ease;
    }

    &:before {
        left: 4px;
        transform: rotate(45deg);
    }

    [aria-expanded="true"] &:before, [aria-selected="true"] &:before {
        transform: rotate(-45deg)
    }

    &:after {
        right: 4px;
        transform: rotate(-45deg);
    }

    [aria-expanded="true"] &:after, [aria-selected="true"] &:after {
        transform: rotate(45deg)
    }

    ${props => props.injectStyle}
`