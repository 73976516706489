
import React from 'react'

import { DiceIcon } from 'components/icons/dice'
import { Lock, Unlocked } from 'components/icons/lock'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemTitleIcons,
    AccordionItemBody,
    AccordionArrow
} from 'components/accordion'

export class GeneratorSection extends React.Component {

    /* ------------------------------------
        Lifecycle Functions
    ------------------------------------ */

    constructor(props) {
        super(props)
        this.state = {
            generatedOptions: []
        }
        this.generators = []
    }

    componentDidMount() {
        this._generate()
    }

    render() {

        let { title, description, subsections } = this.props
        let { generatedOptions } = this.state

        let hasBody = (subsections || (generatedOptions && generatedOptions.length > 0))

        return <AccordionItem>

            <AccordionItemTitle>
                <p><b>{title}</b><br />{description}</p>
                <AccordionItemTitleIcons>
                    <DiceIcon onClick={this._generate} style={{ fontSize: '1.5em' }} />
                    {this.state.isLocked
                        ? <Lock onClick={this._toggleLock} />
                        : <Unlocked onClick={this._toggleLock} />
                    }
                    {hasBody && <AccordionArrow />}
                </AccordionItemTitleIcons>
                
            </AccordionItemTitle>

            {hasBody &&
                <AccordionItemBody>

                    {generatedOptions.map(option => {
                        return <React.Fragment key={option.title}>

                            <p>{option.title}</p>

                            {option.subsections && option.subsections.map(subsection => {
                                return <Accordion key={subsection.title}>
                                    <GeneratorSection
                                        id={subsection.title}
                                        ref={ref => this.generators.push(ref)}
                                        {...subsection}
                                    />
                                </Accordion>

                            })}

                        </React.Fragment>
                    })}

                    {subsections && subsections.map(section => {
                        return <Accordion key={section.title}>
                            <GeneratorSection
                                id={section.title}
                                ref={ref => this.generators.push(ref)}
                                {...section}
                            />
                        </Accordion>
                    })}

                </AccordionItemBody>
            }

        </AccordionItem>

    }

    /* ------------------------------------
        Class Functions
    ------------------------------------ */

    _roll = ({ response = [], options = [] }) => {

        if (options.length > 0) {

            let randomOption = options[Math.floor(Math.random() * options.length)]
            response.push(randomOption)

            if (randomOption.options) {
                response.push(...this._roll({ options: randomOption.options }))
            }

        }

        return response
    }

    _generate = (e) => {

        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        if (this.props.options && !this.state.isLocked) {

            this.setState({
                generatedOptions: this._roll({ options: this.props.options })
            })

        }

        if (!this.state.isLocked) {
            this.generators.forEach(generator => {
                if (generator) { generator._generate() }
            })
        }

    }

    _lock = () => {
        this.setState({ isLocked: true })
        this._lockChildren(true)
    }

    _unlock = () => {
        this.setState({ isLocked: false })
        this._lockChildren(false)
    }

    _toggleLock = (e) => {
        if (e) { e.stopPropagation() }

        let lock = !this.state.isLocked

        this.setState({ isLocked: lock })
        this._lockChildren(lock)
    }

    _lockChildren = (lock) => {
        this.generators.forEach(generator => {
            if (generator && lock)  { generator._lock() }
            else if (generator)     { generator._unlock() }
        })
    }

}